var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reg-page"},[_c('div',{staticClass:"reg-page-main"},[_vm._m(0),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"placeholder":"车场编号","name":"sid","rules":[
                 { required: true, message: '请输入车场编号' } ],"label":"车场编号"},model:{value:(_vm.form.sid),callback:function ($$v) {_vm.$set(_vm.form, "sid", $$v)},expression:"form.sid"}}),_c('div',{staticClass:"comment"},[_vm._v("请联系管理员获取车场编号")]),_c('van-field',{attrs:{"placeholder":"手机号","name":"account","rules":[
                 { required: true, message: '请输入手机号' },
                 { required: true, validator: _vm.checkPhone, message: '手机号格式错误' }
                 ],"label":"手机号"},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}}),_c('van-field',{attrs:{"placeholder":"用户名","name":"name","rules":[{ required: true, message: '请输入用户名' }],"label":"用户名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('van-field',{attrs:{"placeholder":"密码","name":"password","type":"password","rules":[{ required: true, message: '请输入密码' }],"label":"密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('van-field',{attrs:{"placeholder":"确认密码","name":"repeatPassword","type":"password","rules":[
                 { required: true, message: '请再次确认密码'},
                 { required: true, validator: _vm.checkPassword, message: '密码不一致' }
                 ],"label":"确认密码"},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v(" 注册提交 ")])],1)],1)],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.platUserTypeShow),callback:function ($$v) {_vm.platUserTypeShow=$$v},expression:"platUserTypeShow"}},[_c('van-picker',{attrs:{"title":"","show-toolbar":"","columns":_vm.platUserTypeColumn},on:{"confirm":_vm.onSelect,"cancel":function($event){_vm.platUserTypeShow = false}}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"park-logo-wrap"},[_c('div',{staticClass:"logo-wrap"},[_c('img',{attrs:{"src":require("../../assets/logo.png"),"alt":""}})]),_c('span',{staticClass:"park-name"},[_vm._v("智慧停车系统")])])}]

export { render, staticRenderFns }
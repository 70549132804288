<template>
  <div class="reg-page">
    <div class="reg-page-main">
      <!--<park-header></park-header>-->
      <div class="park-logo-wrap">
        <div class="logo-wrap">
          <img src="../../assets/logo.png" alt=""/>
        </div>
        <span class="park-name">智慧停车系统</span>
      </div>
      <van-form @submit="onSubmit">
        <van-field v-model="form.sid"
                   placeholder="车场编号"
                   name="sid"
                   :rules="[
                   { required: true, message: '请输入车场编号' },
                   ]"
                   label="车场编号" />
        <div class="comment">请联系管理员获取车场编号</div>
        <van-field v-model="form.account"
                   placeholder="手机号"
                   name="account"
                   :rules="[
                   { required: true, message: '请输入手机号' },
                   { required: true, validator: checkPhone, message: '手机号格式错误' }
                   ]"
                   label="手机号" />
        <van-field v-model="form.name"
                   placeholder="用户名"
                   name="name"
                   :rules="[{ required: true, message: '请输入用户名' }]"
                   label="用户名" />
        <!--<van-field v-model="form.platUserType"-->
                   <!--placeholder="用户类型"-->
                   <!--name="platUserType"-->
                   <!--:disabled="platUserTypeDisabled"-->
                   <!--@click="platUserTypeShow = true"-->
                   <!--:rules="[{ required: true, message: '请选择用户类型' }]"-->
                   <!--label="用户类型" />-->
        <van-field v-model="form.password"
                   placeholder="密码"
                   name="password"
                   type="password"
                   :rules="[{ required: true, message: '请输入密码' }]"
                   label="密码" />
        <van-field v-model="form.repeatPassword"
                   placeholder="确认密码"
                   name="repeatPassword"
                   type="password"
                   :rules="[
                   { required: true, message: '请再次确认密码'},
                   { required: true, validator: checkPassword, message: '密码不一致' }
                   ]"
                   label="确认密码" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            注册提交
          </van-button>
        </div>
      </van-form>

    </div>
    <van-popup position="bottom" v-model="platUserTypeShow">
      <van-picker
        title=""
        show-toolbar
        :columns="platUserTypeColumn"
        @confirm="onSelect"
        @cancel="platUserTypeShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as md5 from 'md5'
// import * as mqtt from '../../api/wsconnect.js'
// import * as util from '../../api/util.js'
import { mapState } from 'vuex'
export default {
  name: 'reg-page',
  components: {
    parkHeader
  },
  data () {
    return {
      showModifyDialog: false,
      loading: false,
      platUserType: '',
      platUserTypeDisabled: true,
      platUserTypeShow: false,
      form: {
        sid: '',
        account: '',
        name: '',
        platUserType: '',
        platUserTypeValue: '',
        password: ''
      },
      platUserTypeColumn: [
        {
          text: common.userTypesTxt.owner,
          value: '00010001'
        },
        {
          text: common.userTypesTxt.park,
          value: '00010002'
        },
        {
          text: common.userTypesTxt.groupCompany,
          value: '00010003'
        },
        {
          text: common.userTypesTxt.agent,
          value: '00010004'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    checkPhone (phone) {
      return /^1[3456789]\d{9}$/.test(phone)
    },
    checkPassword (value) {
      return this.form.password === value
    },
    onSubmit (form) {
      console.log(form)
      let data = {
        account: form.account,
        pwd: md5(form.password),
        sid: form.sid,
        name: form.name,
        ...api.getPublicPara('v2')
      }
      api.axiosApiRequests(api.apiUrlList.reg, data)
        .then(res => {
          if (res.code == common.resCode.OK) {
            this.$toast('注册成功！')
            sessionStorage.setItem('regAccoutPhone', form.account)
            let href = location.protocol + '//' + location.host + '/#/login'
            console.log('href', href)
            location.href = href
          } else {
            this.$toast.fail('注册失败')
          }
        })
    },
    onFail() {
      console.log('onFail;')
    },
    setDefaultPlatUserType () {
      let platUserType = common.getProp('plat_user_type')
      this.form.platUserTypeValue = platUserType || '00010001'
      this.form.platUserType  = common.translateUserType(platUserType)
    },
    onSelect (value) {
      this.form.platUserTypeValue = value.value
      this.form.platUserType = value.text
      this.platUserTypeShow = false
    }
  },
  mounted () {
    this.setDefaultPlatUserType ()
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.reg-page {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .reg-page-bnt-wrap{
      box-sizing: border-box;
      padding: 2rem 1rem;
      .bnt-top-wrap{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        .van-button{
          width: 46%;
        }
        .cancel{
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .bnt-bottom-wrap{
        margin-top: 1rem;
        .van-button{
          background: rgba(0, 0, 0, 0.1);
        }
      }

    }
  }
  .modify-footer{
    margin: 16px;
    display: flex;
    justify-content: space-between;
    .van-button{
      width: 45%;
    }
  }
  .park-logo-wrap{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    .logo-wrap{
      width: 3rem;
      height: 3.8rem;
      & > img{
        width: 100%;
        height: 100%;
      }
    }
    .park-name{
      padding-left: 1rem;
      font-weight: bold;
      font-size: $global-font-size-2X-large;
    }
  }
  .comment{
    /*padding-bottom: 1rem;*/
    font-size: $global-font-size-mini;
    color: $global-theme-gray;
  }
}
</style>
